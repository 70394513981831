import { Cart } from 'shopify-types'

import { storefrontClient } from '..'
import { GlobalRequestOptions } from '../types'
import { GQL_CART_MUTATION_QUERY, GQLCartMutation, mapGQLCartToCart } from './graphql'

type ApplyGiftcardResponse = Readonly<{
  cartGiftCardCodesUpdate: GQLCartMutation
}>

export const applyGiftCard =
  (options: GlobalRequestOptions) =>
  async (cartId: string, giftCardCodes: string[]): Promise<Cart> => {
    const response = await storefrontClient.request<ApplyGiftcardResponse>(
      `#graphql
        mutation cartGiftCardCodesUpdate($cartId: ID!, $giftCardCodes: [String!]!, $language: LanguageCode) @inContext(language: $language) {
          cartGiftCardCodesUpdate(cartId: $cartId, giftCardCodes: $giftCardCodes) {
            ${GQL_CART_MUTATION_QUERY}
          }
        }
      `,
      {
        variables: {
          cartId,
          giftCardCodes,
          language: options.language,
        },
      },
    )

    if (!response.data?.cartGiftCardCodesUpdate.cart) {
      throw new Error('Failed to apply giftcard code')
    }

    return mapGQLCartToCart(response.data.cartGiftCardCodesUpdate.cart)
  }
