const LOG_LEVEL = process.env.LOG_LEVEL || 'DEBUG'

const shouldLog = (level: 'INFO' | 'DEBUG' | 'ERROR' | 'WARN') => {
  // Always log everything on client
  if (typeof window !== 'undefined') return true

  switch (level) {
    case 'DEBUG':
      return LOG_LEVEL === 'DEBUG'
    case 'INFO':
      return LOG_LEVEL === 'DEBUG' || LOG_LEVEL === 'INFO'
    case 'WARN':
      return LOG_LEVEL === 'DEBUG' || LOG_LEVEL === 'INFO' || LOG_LEVEL === 'WARN'
    case 'ERROR':
    default:
      return true
  }
}

type LogOptions = string | Partial<{ group: string }>

const log = (
  options: LogOptions,
  message: unknown[],
  type: 'INFO' | 'DEBUG' | 'ERROR' | 'WARN',
) => {
  if (shouldLog(type)) {
    const group = typeof options === 'string' ? options : options.group
    console.log(`[${type}]${group ? `[${group}]` : ''}`, ...message)
  }
}

export const logger = {
  error: (options: LogOptions, ...message: unknown[]) => log(options, message, 'ERROR'),
  warn: (options: LogOptions, ...message: unknown[]) => log(options, message, 'WARN'),
  info: (options: LogOptions, ...message: unknown[]) => log(options, message, 'INFO'),
  debug: (options: LogOptions, ...message: unknown[]) => log(options, message, 'DEBUG'),
}
