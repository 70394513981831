import { Cart } from 'shopify-types'

import { storefrontClient } from '..'
import { GlobalRequestOptions } from '../types'
import { GQL_CART_MUTATION_QUERY, GQLCartMutation, mapGQLCartToCart } from './graphql'

type ApplyDiscountResponse = Readonly<{
  cartDiscountCodesUpdate: GQLCartMutation
}>

export const applyDiscount =
  (options: GlobalRequestOptions) =>
  async (cartId: string, discountCodes: string[]): Promise<Cart> => {
    const response = await storefrontClient.request<ApplyDiscountResponse>(
      `#graphql
        mutation cartDiscountCodesUpdate($cartId: ID!, $discountCodes: [String!], $language: LanguageCode) @inContext(language: $language) {
          cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
            ${GQL_CART_MUTATION_QUERY}
          }
        }
      `,
      {
        variables: {
          cartId,
          discountCodes,
          language: options.language,
        },
      },
    )

    if (!response.data?.cartDiscountCodesUpdate.cart) {
      throw new Error('Failed to apply discount code')
    }

    return mapGQLCartToCart(response.data.cartDiscountCodesUpdate.cart)
  }
