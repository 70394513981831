import { Customer } from 'shopify-types'

import { storefrontClient } from '..'
import { GlobalRequestOptions } from '../types'
import {
  GQL_CUSTOMER_MUTATION_QUERY,
  GQLCustomerMutation,
  mapGQLCustomerToCustomer,
} from './graphql'

export type CreateCustomerInput = Readonly<{
  acceptsMarketing: boolean
  email: string
  firstName: string
  lastName: string
  phone: string
  password: string
}>

type CreateCustomerResponse = Readonly<{
  customerCreate: GQLCustomerMutation
}>

export const createCustomer =
  (options: GlobalRequestOptions) =>
  async (input: CreateCustomerInput): Promise<Customer> => {
    const response = await storefrontClient.request<CreateCustomerResponse>(
      `#graphql
      mutation customerCreate($input: CustomerCreateInput!, $language: LanguageCode) @inContext(language: $language) {
        customerCreate(input: $input) {
          ${GQL_CUSTOMER_MUTATION_QUERY}
        }
      }
    `,
      {
        variables: {
          input,
          language: options.language,
        },
      },
    )

    if (!response.data?.customerCreate.customer) {
      throw new Error('Failed to create customer')
    }

    return mapGQLCustomerToCustomer(response.data.customerCreate.customer)
  }
