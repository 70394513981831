import { CustomerUserError } from 'shopify-types'

import { storefrontClient } from '../..'

type RemoveAddressResponse = Readonly<{
  customerAddressDelete: {
    deletedCustomerAddressId: string
    customerUserErrors: CustomerUserError[]
  }
}>

export const removeAddress = () => async (token: string, addressId: string) => {
  const response = await storefrontClient.request<RemoveAddressResponse>(
    `#graphql
      mutation customerAddressDelete($customerAccessToken: String!, $id: ID!) {
        customerAddressDelete(customerAccessToken: $customerAccessToken, id: $id) {
          deletedCustomerAddressId
          customerUserErrors {
            code
            field
            message
          }
        }
      }
    `,
    {
      variables: {
        id: addressId,
        customerAccessToken: token,
      },
    },
  )

  if (!response.data?.customerAddressDelete.deletedCustomerAddressId) {
    throw new Error('Failed to delete address')
  }

  return response.data.customerAddressDelete.deletedCustomerAddressId
}
