import { withGlobalOptions } from '../../types'
import { createAddress } from './create'
import { removeAddress } from './remove'
import { setDefaultAddress } from './set-default'
import { updateAddress } from './update'

export const addressHandles = withGlobalOptions({
  create: createAddress,
  remove: removeAddress,
  update: updateAddress,
  setDefault: setDefaultAddress,
})
