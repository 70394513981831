import { Product, ProductVariant } from 'shopify-types'

import { GQLMoney, mapGQLMoneyToMoney } from '../graphql'

export type GQLProductVariant = Readonly<{
  id: string
  sku: string
  title: string
  availableForSale: boolean
  price: GQLMoney
  unitPrice: GQLMoney
  unitPriceMeasurement: {
    referenceValue: number
    referenceUnit: string
  }
  compareAtPrice?: GQLMoney
  image: {
    id: string
    src: string
    altText?: string
    width: number
    height: number
  }
  product: {
    id: string
    handle: string
    title: string
    truncatedDescription: string
  }
  hidden?: {
    value: string
  }
  selectedOptions: {
    name: string
    value: string
  }[]
}>

export const GQL_VARIANT_FRAGMENT = `#graphql
  id
  sku
  title
  availableForSale
  price {
    amount
    currencyCode
  }
  unitPrice {
    amount
    currencyCode
  }
  unitPriceMeasurement {
    referenceValue
    referenceUnit
  }
  compareAtPrice {
    amount
    currencyCode
  }
  image {
    id
    src: url
    altText
    width
    height
  }
  product {
    id
    handle
    title
    truncatedDescription: description(truncateAt: 100)
  }
  hidden: metafield(namespace: "beyer", key: "hidden") {
    value
  }
  selectedOptions {
    name
    value
  }
` as const

export const mapGQLProductVariantToProductVariant = (
  variant: GQLProductVariant,
): ProductVariant => ({
  ...variant,
  price: mapGQLMoneyToMoney(variant.price),
  unitPrice: mapGQLMoneyToMoney(variant.unitPrice),
  compareAtPrice: mapGQLMoneyToMoney(variant.compareAtPrice),
  hidden: variant.hidden?.value === 'true',
})

export type GQLProduct = Readonly<{
  id: string
  availableForSale: boolean
  totalInventory: number
  requiresSellingPlan: boolean
  descriptionHtml: string
  description: string
  handle: string
  productType: string
  title: string
  vendor: string
  images: {
    edges: {
      node: {
        id: string
        src: string
        altText: string
        width: number
        height: number
      }
    }[]
  }
  variants: {
    edges: {
      node: GQLProductVariant
    }[]
  }
  options: {
    id: string
    name: string
    values: string[]
  }[]
}>

export const GQL_PRODUCT_FRAGMENT = `#graphql
  id
  availableForSale
  requiresSellingPlan
  descriptionHtml
  description
  handle
  productType
  title
  vendor
  images(first: 250) {
    edges {
      node {
        id
        src: url
        altText
        width
        height
      }
    }
  }
  variants(first: 250) {
    edges {
      node {
        ${GQL_VARIANT_FRAGMENT}
      }
    }
  }
  options {
    id
    name
    values
  }  
` as const

export const mapGQLProductToProduct = (product: GQLProduct): Product => ({
  ...product,
  images: product.images.edges.map(edge => edge.node),
  variants: product.variants.edges.map(edge => mapGQLProductVariantToProductVariant(edge.node)),
})
