import { createStorefrontApiClient } from '@shopify/storefront-api-client'

import { logger } from '@/helpers/logger'

import { cartHandles } from './cart'
import { customerHandles } from './customer'
import { productHandles } from './product'
import { withGlobalOptions } from './types'

export const storefrontClient = createStorefrontApiClient({
  storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_HOST,
  apiVersion: '2025-01',
  publicAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_ACCESS_TOKEN,
  logger: log => {
    logger.debug(`SHOPIFY (${log.type})`, log.content)
  },
})

export const shopify = withGlobalOptions({
  cart: cartHandles,
  product: productHandles,
  customer: customerHandles,
})
