'use client'

import clsx from 'clsx'
import { useEffect, useMemo, useState } from 'react'
import useFontFaceObserver from 'use-font-face-observer'

type IconProps = {
  name: string
  size?: 16 | 20 | 24 | 26 | 30 | 32 | 40
  className?: string
}

/**
 * Converts Icon name from the MdOutline or Md prefix to the format used in the Icon component
 *
 * e.g. MdOutlineReceiptLong -> receipt_long
 *      MdChevronLeft -> chevron_left
 *
 * @param name - Icon name
 * @returns Converted Icon name
 */
const convertIconName = (name: string) =>
  name?.startsWith('Md')
    ? name
        .replace(/^(MdOutline|Md)/, '')
        .split(/(?=[A-Z])/)
        .join('_')
        .toLowerCase()
    : name

export default function Icon({ name, size = 16, className, ...props }: IconProps) {
  const [fontFamily, setFontFamily] = useState<string>('')
  const iconName = useMemo(() => convertIconName(name), [name])
  const filled = name.match(/^Md(?!Outline)/)

  useEffect(() => {
    setFontFamily(
      window
        .getComputedStyle(document.documentElement)
        .getPropertyValue('--font-family-symbols')
        .match(/['"]([^'"]*)['"]/)?.[1] ?? '',
    )
  }, [])

  const isFontLoaded = useFontFaceObserver(
    [
      {
        family: fontFamily,
      },
    ],
    {
      testString: 'checkbox',
      timeout: 30000,
    },
  )

  return (
    <i
      {...props}
      className={clsx(
        'symbol',
        filled && 'filled',
        size === 16 && 'h-4 w-4 text-[16px]',
        size === 20 && 'h-5 w-5 text-[20px]',
        size === 24 && 'h-6 w-6 text-[24px]',
        size === 26 && 'h-[26px] w-[26px] text-[26px]',
        size === 30 && 'h-[30px] w-[30px] text-[30px]',
        size === 32 && 'h-8 w-8 text-[32px]',
        size === 40 && 'h-10 w-10 text-[40px]',
        className,
      )}
    >
      {isFontLoaded && iconName}
    </i>
  )
}
