import { Money } from 'shopify-types/shared'

export type GQLMoney = {
  amount: string
  currencyCode: string
}

export const mapGQLMoneyToMoney = <T extends GQLMoney | undefined>(
  money: T,
): T extends GQLMoney ? Money : undefined => {
  if (!money) return undefined as T extends GQLMoney ? Money : undefined
  return {
    amount: parseFloat(money.amount),
    currencyCode: money.currencyCode,
  } as T extends GQLMoney ? Money : undefined
}
