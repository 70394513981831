import { Address } from 'shopify-types'

export type GQLAddress = Readonly<{
  id: string
  firstName: string
  lastName: string
  company: string
  address1: string
  address2: string
  city: string
  country: string
  province: string
  zip: string
  phone: string
}>

export const GQL_ADDRESS_FRAGMENT = `#graphql
  id
  firstName
  lastName
  company
  address1
  address2
  city
  country
  province
  zip
  phone
` as const

export const mapGQLAddressToAddress = (address: GQLAddress): Address => ({
  ...address,
})
