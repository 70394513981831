import { UserError } from 'shopify-types'

import { storefrontClient } from '..'

type LogoutCustomerResponse = Readonly<{
  customerAccessTokenDelete: {
    deletedAccessToken: string
    userErrors: UserError[]
  }
}>

export const logoutCustomer =
  () =>
  async (token: string): Promise<boolean> => {
    const response = await storefrontClient.request<LogoutCustomerResponse>(
      `#graphql
        mutation customerAccessTokenDelete($customerAccessToken: String!) {
          customerAccessTokenDelete(customerAccessToken: $customerAccessToken) {
            deletedAccessToken
            userErrors {
              field
              message
            }
          }
        }
      `,
      {
        variables: {
          customerAccessToken: token,
        },
      },
    )

    if (!response.data?.customerAccessTokenDelete.deletedAccessToken) {
      throw new Error('Failed to logout customer')
    }

    return true
  }
