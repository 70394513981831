import { Product } from 'shopify-types'

import { storefrontClient } from '..'
import { GlobalRequestOptions } from '../types'
import { GQL_PRODUCT_FRAGMENT, type GQLProduct, mapGQLProductToProduct } from './graphql'

type FetchProductResponse = Readonly<{
  product: GQLProduct
}>

export const fetchProduct =
  (options: GlobalRequestOptions) =>
  async (id: string): Promise<Product> => {
    const response = await storefrontClient.request<FetchProductResponse>(
      `#graphql
        query getProductById($id: ID!, $language: LanguageCode) @inContext(language: $language) {
          product(id: $id) {
            ${GQL_PRODUCT_FRAGMENT}
          }
        }
      `,
      {
        variables: {
          id,
          language: options.language,
        },
      },
    )

    if (!response.data?.product) {
      throw new Error('Product not found')
    }

    return mapGQLProductToProduct(response.data.product)
  }
