'use client'

import clsx from 'clsx'
import { type HTMLMotionProps, LazyMotion, m } from 'framer-motion'
import Link from 'next/link'
import { useRef } from 'react'
import { useButton } from 'react-aria'

import Icon from '../Icon'
import Loader from '../Loader'

const motionFeatures = () => import('ui/helpers/motionFeatures').then(res => res.default)

const MLink = m(Link)

export type ButtonProps = {
  children?: string
  className?: string
  textClassName?: string
  isDisabled?: boolean
  onPress?: () => void
  href?: string
  target?: JSX.IntrinsicElements['a']['target']
  withArrow?: boolean
  appearance: 'dark' | 'transparent' | 'light' | 'primary'
  isLoading?: boolean
  /**
   * Type `button` is default
   */
  type?: 'submit' | 'reset'
  tabIndex?: number
  animationProps?: HTMLMotionProps<'a' | 'button'>
}

export default function Button(props: ButtonProps) {
  const ref = useRef<HTMLButtonElement>(null)
  // @ts-ignore
  const { buttonProps } = useButton(props, ref)

  const withText = props.children != null && props.children.length !== 0

  const textColor = (
    { dark: 'white', primary: 'white', transparent: 'black', light: 'black' } as const
  )[props.appearance]

  const content = (
    <>
      {props.isLoading ? (
        <Loader color={textColor} />
      ) : (
        <>
          {withText && (
            <p className={clsx('truncate lg:text-[18px] xl:text-[24px]', props.textClassName)}>
              {props.children}
            </p>
          )}

          {props.withArrow && (
            <Icon
              name='MdArrowForwardIos'
              size={16}
              className={clsx(
                'transition',
                {
                  'group-hover:scale-110': !props.isDisabled,
                  'xl:h-12 xl:w-12': !withText,
                },
                textColor === 'white' ? 'text-white' : 'text-black',
              )}
            />
          )}
        </>
      )}
    </>
  )

  const parentProps = {
    className: clsx(
      'group flex w-full items-center justify-center gap-2 rounded-xs no-underline focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:max-w-[335px] xl:max-w-[440px] xl:rounded-sm',
      {
        dark: 'bg-black text-white',
        primary: 'bg-black/90 text-white',
        light: ' bg-lightgray text-black outline outline-1 outline-black',
        transparent: 'bg-transparent text-black outline outline-1 outline-black',
      }[props.appearance],
      `${
        props.isLoading
          ? 'px-14 py-3 xl:py-[17px]'
          : withText
          ? 'px-10 py-3.5 xl:px-14 xl:py-5'
          : 'p-3.5 xl:p-[13px]'
      }`,
      props.isDisabled || (props.isLoading && 'pointer-events-none cursor-default'),
      props.className,
    ),
    ...props.animationProps,
  }

  if (props.href != null) {
    return (
      <LazyMotion features={motionFeatures} strict>
        {/* @ts-ignore */}
        <MLink href={props.href} target={props.target} tabIndex={props.tabIndex} {...parentProps}>
          {content}
        </MLink>
      </LazyMotion>
    )
  }

  return (
    <LazyMotion features={motionFeatures} strict>
      {/* @ts-ignore */}
      <m.button
        tabIndex={props.tabIndex}
        {...buttonProps}
        ref={ref}
        {...parentProps}
        disabled={props.isDisabled || props.isLoading}
      >
        {content}
      </m.button>
    </LazyMotion>
  )
}
