import { CustomerAccessToken, UserError } from 'shopify-types'

import { storefrontClient } from '..'

type RefreshCustomerResponse = Readonly<{
  customerAccessTokenRenew: {
    customerAccessToken: CustomerAccessToken
    userErrors: UserError[]
  }
}>

export const refreshCustomer =
  () =>
  async (token: string): Promise<CustomerAccessToken> => {
    const response = await storefrontClient.request<RefreshCustomerResponse>(
      `#graphql
      mutation customerAccessTokenRenew($customerAccessToken: String!) {
        customerAccessTokenRenew(customerAccessToken: $customerAccessToken) {
          customerAccessToken {
            accessToken
            expiresAt
          }
          userErrors {
            field
            message
          }
        }
      }
    `,
      {
        variables: {
          customerAccessToken: token,
        },
      },
    )

    if (!response.data?.customerAccessTokenRenew.customerAccessToken.accessToken) {
      throw new Error('Failed to refresh customer token')
    }

    return response.data.customerAccessTokenRenew.customerAccessToken
  }
