import { Cart, CartUserError, CartWarning } from 'shopify-types'

import { GQLMoney, mapGQLMoneyToMoney } from '../graphql'
import {
  GQL_VARIANT_FRAGMENT,
  type GQLProductVariant,
  mapGQLProductVariantToProductVariant,
} from '../product/graphql'

export type GQLCart = Readonly<{
  id: string
  checkoutUrl: string
  totalQuantity: number
  cost: {
    checkoutChargeAmount: GQLMoney
    totalAmount: GQLMoney
  }
  lines: {
    edges: {
      node: {
        id: string
        merchandise: GQLProductVariant
        quantity: number
      }
    }[]
  }
  buyerIdentity: {
    email?: string
    customer?: {
      id: string
      email: string
    }
  }
  appliedGiftCards: {
    id: string
    amountUsed: GQLMoney
    balance: GQLMoney
    lastCharacters: string
  }[]
  discountCodes: {
    applicable: boolean
    code: string
  }[]
}>

export const GQL_CART_FRAGMENT = `#graphql
  id
  checkoutUrl
  totalQuantity
  cost {
    checkoutChargeAmount {
      amount
      currencyCode
    }
    totalAmount {
      amount
      currencyCode
    }
  }
  lines (first: 250) {
    edges {
      node {
        id
        merchandise {
          ... on ProductVariant {
            ${GQL_VARIANT_FRAGMENT}
          }
        }
        quantity
      }
    }
  }
  buyerIdentity {
    countryCode
    email
    phone
    customer {
      id
      email
      firstName
      lastName
    }
  }
  appliedGiftCards {
    id
    amountUsed {
      amount
      currencyCode
    }
    balance {
      amount
      currencyCode
    }
    lastCharacters
  }
  discountCodes {
    applicable
    code
  }
` as const

export const mapGQLCartToCart = (cart: GQLCart): Cart => ({
  ...cart,
  cost: {
    checkoutChargeAmount: mapGQLMoneyToMoney(cart.cost.checkoutChargeAmount),
    totalAmount: mapGQLMoneyToMoney(cart.cost.totalAmount),
  },
  lines: cart.lines.edges.map(edge => ({
    ...edge.node,
    merchandise: mapGQLProductVariantToProductVariant(edge.node.merchandise),
  })),
  appliedGiftCards: cart.appliedGiftCards.map(giftCard => ({
    ...giftCard,
    amountUsed: mapGQLMoneyToMoney(giftCard.amountUsed),
    balance: mapGQLMoneyToMoney(giftCard.balance),
  })),
})

export type GQLCartMutation = Readonly<{
  cart: GQLCart
  userErrors: CartUserError[]
  warnings: CartWarning[]
}>

export const GQL_CART_MUTATION_QUERY = `#graphql
  cart {
    ${GQL_CART_FRAGMENT}
  }
  userErrors {
    code
    field
    message
  }
  warnings {
    code
    message
    target
  }
` as const

export type GQLBuyerIdentityInput = Readonly<{
  customerAccessToken?: string
  email?: string
  phone?: string
}>
