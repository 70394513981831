import { withGlobalOptions } from '../types'
import { buyerIdentityUpdate } from './buyer-update'
import { createCart } from './create'
import { applyDiscount } from './discount-apply'
import { fetchCart } from './fetch'
import { applyGiftCard } from './giftcard-apply'
import { createCartLine } from './line-create'
import { removeCartLine } from './line-remove'
import { updateCartLine } from './line-update'

export const cartHandles = withGlobalOptions({
  fetch: fetchCart,
  create: createCart,
  lineCreate: createCartLine,
  lineUpdate: updateCartLine,
  lineRemove: removeCartLine,
  discountApply: applyDiscount,
  giftCardApply: applyGiftCard,
  buyerIdentityUpdate: buyerIdentityUpdate,
})
