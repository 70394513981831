export type GlobalRequestOptions = {
  language: string
}

// Helper function to apply global options to all handles - looks more complex than it is due to TypeScript
export const applyGlobalOptions = <
  T extends {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: (globalOptions: GlobalRequestOptions) => any
  },
>(
  options: GlobalRequestOptions,
  handles: T,
): { [key in keyof T]: ReturnType<T[key]> } =>
  Object.keys(handles).reduce(
    (acc, key) => ({
      ...acc,
      [key]: handles[key](options),
    }),
    {},
  ) as { [key in keyof T]: ReturnType<T[key]> }

export const withGlobalOptions =
  <
    T extends {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: (globalOptions: GlobalRequestOptions) => any
    },
  >(
    handles: T,
  ): ((options: GlobalRequestOptions) => { [key in keyof T]: ReturnType<T[key]> }) =>
  (options: GlobalRequestOptions) =>
    applyGlobalOptions(options, handles)
