import { GlobalTypography } from 'cms-types'
import { create } from 'zustand'

export const useGlobalTypography = create<Omit<GlobalTypography, 'id' | 'createdAt' | 'updatedAt'>>(
  () => ({
    magicLinksToastsCustomization: {
      products: {
        success: '',
        error: '',
      },
      discounts: {
        success: '',
        error: '',
      },
      giftCards: {
        allCardsSuccess: '',
        oneCardSuccess: '',
        invalidCardError: '',
        cardAlreadyAppliedError: '',
      },
    },
    floatingButton: {
      phone: '',
      message: '',
    },
    productPage: {
      accessibility: {
        price: '',
        compareAtPrice: '',
      },
      addToCartButtonText: '',
      shoppingButtonLabel: '',
      reviewsText: '',
      reviewsBlock: {
        title: '',
        fromText: '',
        verifiedCustomerText: '',
      },
      itemIsOutOfStockButtonText: '',
      itemIsOutOfStockEmailNotificationText: {
        root: {
          type: 'root',
          format: '',
          indent: 0,
          version: 1,
          children: [],
          direction: 'ltr',
        },
      },
      itemIsOutOfStockEmailNotificationAccessibleTitle: '',
      outOfStockTag: {
        title: '',
      },
      backButton: {
        fallbackLink: {
          title: '',
          page: undefined,
        },
        backLinkText: '',
      },
    },
    blogPostPage: {
      previewLinkTitle: '',
    },
    ingredientsPage: {
      blogPostLinkTitle: '',
      importantIngredientsTitle: '',
      restIngredientsTitle: '',
      searchBarLabel: '',
      maxItemsPerPage: 0,
      pageTitle: '',
      pageDescription: '',
      navigationTitle: '',
    },
    authSection: {
      accountPageTitle: '',
      logInPageTitle: '',
      signUpPageTitle: '',
      recoverPasswordPageTitle: '',
      logInButton: '',
      emailText: '',
      passwordText: '',
      passwordConfirmText: '',
      firstNameText: '',
      lastNameText: '',
      phoneNumberText: '',
      receiveMarketingText: '',
      passwordRecoveryInstructionsText: '',
      ordersTabTitle: '',
      addressesTabTitle: '',
      isNotValidText: '',
      notFoundText: '',
      isRequiredText: '',
      isTooShortText: '',
      invalidCredentialsText: '',
      somethingWentWrongText: '',
      signUpButton: '',
      recoverPasswordButton: '',
      submitButtonText: '',
      cancelButton: '',
      ordersTab: {
        orderIdColumnTitle: '',
        orderDateColumnTitle: '',
        orderTotalColumnTitle: '',
        orderStatusColumnTitle: '',
        orderDeliveryColumnTitle: '',
        orderActionsColumnTitle: '',
        orderTitleColumnTitle: '',
        orderPriceColumnTitle: '',
        orderQuantityColumnTitle: '',
        reorderButtonText: '',
        viewDetailsButtonText: '',
      },
      addressesTab: {
        addAddressButton: '',
        deleteAddressButton: '',
        editAddressButton: '',
        defaultAddressText: '',
        editAddressTitle: '',
        countryText: '',
        cityText: '',
        provinceText: '',
        zipCodeText: '',
        addressLine1Text: '',
        addressLine2Text: '',
        phoneNumberText: '',
        companyText: '',
        makeDefaultAddressText: '',
        deleteAddressConfirmationText: '',
      },
    },
  }),
)
