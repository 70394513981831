import { Cart } from 'shopify-types'

import { storefrontClient } from '..'
import { GlobalRequestOptions } from '../types'
import {
  GQL_CART_MUTATION_QUERY,
  GQLBuyerIdentityInput,
  GQLCartMutation,
  mapGQLCartToCart,
} from './graphql'

type BuyerIdentityUpdateResponse = Readonly<{
  cartBuyerIdentityUpdate: GQLCartMutation
}>

export const buyerIdentityUpdate =
  (options: GlobalRequestOptions) =>
  async (cartId: string, buyerIdentity: GQLBuyerIdentityInput): Promise<Cart> => {
    const response = await storefrontClient.request<BuyerIdentityUpdateResponse>(
      `#graphql
        mutation cartBuyerIdentityUpdate($cartId: ID!, $buyerIdentity: CartBuyerIdentityInput!, $language: LanguageCode) @inContext(language: $language) {
          cartBuyerIdentityUpdate(cartId: $cartId, buyerIdentity: $buyerIdentity) {
            ${GQL_CART_MUTATION_QUERY}
          }
        }
      `,
      {
        variables: {
          cartId,
          buyerIdentity,
          language: options.language,
        },
      },
    )

    if (!response.data?.cartBuyerIdentityUpdate.cart) {
      throw new Error('Failed to update buyer identity')
    }

    return mapGQLCartToCart(response.data.cartBuyerIdentityUpdate.cart)
  }
