import { Cart } from 'shopify-types'

import { storefrontClient } from '..'
import { GlobalRequestOptions } from '../types'
import {
  GQL_CART_MUTATION_QUERY,
  GQLBuyerIdentityInput,
  GQLCartMutation,
  mapGQLCartToCart,
} from './graphql'

type CreateCartInput = Readonly<{
  buyerIdentity?: GQLBuyerIdentityInput
}>

type CreateCartResponse = Readonly<{
  cartCreate: GQLCartMutation
}>

export const createCart =
  (options: GlobalRequestOptions) =>
  async (input?: CreateCartInput): Promise<Cart> => {
    const response = await storefrontClient.request<CreateCartResponse>(
      `#graphql
        mutation cartCreate($input: CartInput!, $language: LanguageCode) @inContext(language: $language) {
          cartCreate(input: $input) {
            ${GQL_CART_MUTATION_QUERY}
          }
        }
      `,
      {
        variables: {
          input: input || {},
          language: options.language,
        },
      },
    )

    if (!response.data?.cartCreate.cart) {
      throw new Error('Failed to create cart')
    }

    return mapGQLCartToCart(response.data.cartCreate.cart)
  }
