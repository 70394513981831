import { Customer, CustomerUserError } from 'shopify-types'

import { storefrontClient } from '@/shopify'
import { GlobalRequestOptions } from '@/shopify/types'

import { GQL_CUSTOMER_FRAGMENT, GQLCustomer, mapGQLCustomerToCustomer } from '../graphql'

type DefaultAddressUpdateResponse = Readonly<{
  customerDefaultAddressUpdate: {
    customer: GQLCustomer
    customerUserErrors: CustomerUserError[]
  }
}>

export const setDefaultAddress =
  (options: GlobalRequestOptions) =>
  async (token: string, addressId: string): Promise<Customer> => {
    const response = await storefrontClient.request<DefaultAddressUpdateResponse>(
      `#graphql
      mutation customerDefaultAddressUpdate($addressId: ID!, $customerAccessToken: String!, $language: LanguageCode) @inContext(language: $language) {
        customerDefaultAddressUpdate(
          addressId: $addressId
          customerAccessToken: $customerAccessToken
        ) {
          customer {
            ${GQL_CUSTOMER_FRAGMENT}
          }
          customerUserErrors {
            code
            field
            message
          }
        }
      }
    `,
      {
        variables: {
          addressId: addressId,
          customerAccessToken: token,
          language: options.language,
        },
      },
    )

    if (!response.data?.customerDefaultAddressUpdate.customer) {
      throw new Error('Failed to update default address')
    }

    return mapGQLCustomerToCustomer(response.data.customerDefaultAddressUpdate.customer)
  }
