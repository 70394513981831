import { Customer, CustomerUserError } from 'shopify-types'

import { GQL_ADDRESS_FRAGMENT, GQLAddress, mapGQLAddressToAddress } from './address/graphql'
import { GQL_ORDER_FRAGMENT, GQLOrder, mapGQLOrderToOrder } from './order/graphql'

export type GQLCustomer = Readonly<{
  id: string
  email: string
  phone: string
  firstName: string
  lastName: string
  defaultAddress?: GQLAddress
  addresses: {
    edges: {
      node: GQLAddress
    }[]
  }
  orders: {
    edges: {
      node: GQLOrder
    }[]
  }
}>

export const GQL_CUSTOMER_FRAGMENT = `#graphql
  id
  email
  phone
  firstName
  lastName
  defaultAddress {
    ${GQL_ADDRESS_FRAGMENT}
  }
  addresses(first: 250) {
    edges {
      node {
        ${GQL_ADDRESS_FRAGMENT}
      }
    }
  }
  orders(first: 250, sortKey: PROCESSED_AT, reverse: true) {
    edges {
      node {
        ${GQL_ORDER_FRAGMENT}
      }
    }
  }
` as const

export const mapGQLCustomerToCustomer = (customer: GQLCustomer): Customer => ({
  ...customer,
  addresses: customer.addresses.edges.map(address => mapGQLAddressToAddress(address.node)),
  orders: customer.orders.edges.map(order => mapGQLOrderToOrder(order.node)),
})

export type GQLCustomerMutation = Readonly<{
  customer: GQLCustomer
  customerUserErrors: CustomerUserError[]
}>

export const GQL_CUSTOMER_MUTATION_QUERY = `#graphql
  customer {
    ${GQL_CUSTOMER_FRAGMENT}
  }
  customerUserErrors {
    code
    field
    message
  }
` as const
