import clsx from 'clsx'
import React from 'react'

export default function LoadingText({
  className,
  textLength,
  lines = 1,
  inline,
}: {
  className?: string
  textLength?: number
  lines?: number
  inline?: boolean
}) {
  return new Array(lines).fill(null).map((_, index) => (
    <React.Fragment key={`loading-text-${index}`}>
      <span
        className={clsx(
          'block animate-pulse rounded-sm bg-gray text-gray',
          inline && 'inline-block',
          className,
        )}
        aria-hidden='true'
      >
        {textLength ? <span className='invisible'>{'A'.repeat(textLength)}</span> : 'A'}
      </span>
      {lines > 1 && index < lines - 1 && '\n'}
    </React.Fragment>
  ))
}
