import { Address, CustomerUserError } from 'shopify-types'

import { GlobalRequestOptions } from '@/shopify/types'

import { storefrontClient } from '../..'
import { GQL_ADDRESS_FRAGMENT, mapGQLAddressToAddress } from './graphql'

export type CreateAddressInput = Readonly<Omit<Address, 'id' | 'phone' | 'province'>>

type CreateAddressResponse = Readonly<{
  customerAddressCreate: {
    customerAddress: Address
    customerUserErrors: CustomerUserError[]
  }
}>

export const createAddress =
  (options: GlobalRequestOptions) => async (token: string, address: CreateAddressInput) => {
    const response = await storefrontClient.request<CreateAddressResponse>(
      `#graphql
      mutation customerAddressCreate($address: MailingAddressInput!, $customerAccessToken: String!, $language: LanguageCode) @inContext(language: $language) {
        customerAddressCreate(address: $address, customerAccessToken: $customerAccessToken) {
          customerAddress {
            ${GQL_ADDRESS_FRAGMENT}
          }
          customerUserErrors {
            code
            field
            message
          }
        }
      }
    `,
      {
        variables: {
          address,
          customerAccessToken: token,
          language: options.language,
        },
      },
    )

    if (!response.data?.customerAddressCreate.customerAddress) {
      throw new Error('Failed to create address')
    }

    return mapGQLAddressToAddress(response.data?.customerAddressCreate.customerAddress)
  }
