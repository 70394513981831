import { Product } from 'shopify-types'

import { storefrontClient } from '..'
import { GlobalRequestOptions } from '../types'
import { GQL_PRODUCT_FRAGMENT, type GQLProduct, mapGQLProductToProduct } from './graphql'

type FetchMultipleProductsResponse = Readonly<{
  nodes: GQLProduct[]
}>

export const fetchMultipleProducts =
  (options: GlobalRequestOptions) =>
  async (ids: string[]): Promise<Product[]> => {
    if (ids.length === 0) {
      return []
    }

    const response = await storefrontClient.request<FetchMultipleProductsResponse>(
      `#graphql
        query getProductsByIds($ids: [ID!]!, $language: LanguageCode) @inContext(language: $language) {
          nodes(ids: $ids) {
            ... on Product {
              ${GQL_PRODUCT_FRAGMENT}
            }
          }
        }
      `,
      {
        variables: {
          ids,
          language: options.language,
        },
      },
    )

    if (!response.data?.nodes || response.data.nodes.length === 0) {
      throw new Error('Products not found')
    }

    return response.data.nodes.map(mapGQLProductToProduct)
  }
