import { Cart } from 'shopify-types'

import { storefrontClient } from '..'
import { GlobalRequestOptions } from '../types'
import { GQL_CART_MUTATION_QUERY, GQLCartMutation, mapGQLCartToCart } from './graphql'

type UpdateCartLineInput = Readonly<{
  id: string
  merchandiseId?: string
  quantity?: number
}>[]

type UpdateCartLineResponse = Readonly<{
  cartLinesUpdate: GQLCartMutation
}>

export const updateCartLine =
  (options: GlobalRequestOptions) =>
  async (cartId: string, lines: UpdateCartLineInput): Promise<Cart> => {
    const response = await storefrontClient.request<UpdateCartLineResponse>(
      `#graphql
        mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!, $language: LanguageCode) @inContext(language: $language) {
          cartLinesUpdate(cartId: $cartId, lines: $lines) {
            ${GQL_CART_MUTATION_QUERY}
          }
        }
      `,
      {
        variables: {
          cartId,
          lines,
          language: options.language,
        },
      },
    )

    if (!response.data?.cartLinesUpdate.cart) {
      throw new Error('Failed to update line item')
    }

    return mapGQLCartToCart(response.data.cartLinesUpdate.cart)
  }
