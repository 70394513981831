import { Customer } from 'shopify-types'

import { storefrontClient } from '..'
import { GlobalRequestOptions } from '../types'
import { GQL_CUSTOMER_FRAGMENT, type GQLCustomer, mapGQLCustomerToCustomer } from './graphql'

type FetchCustomerResponse = Readonly<{
  customer: GQLCustomer
}>

export const fetchCustomer =
  (options: GlobalRequestOptions) =>
  async (token: string): Promise<Customer> => {
    const response = await storefrontClient.request<FetchCustomerResponse>(
      `#graphql
        query ($customerAccessToken: String!, $language: LanguageCode) @inContext(language: $language) {
          customer(customerAccessToken: $customerAccessToken) {
            ${GQL_CUSTOMER_FRAGMENT}
          }
        }
      `,
      {
        variables: {
          customerAccessToken: token,
          language: options.language,
        },
      },
    )

    if (!response.data?.customer) {
      throw new Error('Failed to fetch customer')
    }

    return mapGQLCustomerToCustomer(response.data.customer)
  }
