import { Cart } from 'shopify-types'

import { storefrontClient } from '..'
import { GlobalRequestOptions } from '../types'
import { GQL_CART_MUTATION_QUERY, GQLCartMutation, mapGQLCartToCart } from './graphql'

type CreateCartLineInput = Readonly<{
  merchandiseId?: string
  quantity?: number
}>[]

type CreateCartLineResponse = Readonly<{
  cartLinesAdd: GQLCartMutation
}>

export const createCartLine =
  (options: GlobalRequestOptions) =>
  async (cartId: string, lines: CreateCartLineInput): Promise<Cart> => {
    const response = await storefrontClient.request<CreateCartLineResponse>(
      `#graphql
        mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!, $language: LanguageCode) @inContext(language: $language) {
          cartLinesAdd(cartId: $cartId, lines: $lines) {
            ${GQL_CART_MUTATION_QUERY}
          }
        }
      `,
      {
        variables: {
          cartId,
          lines,
          language: options.language,
        },
      },
    )

    if (!response.data?.cartLinesAdd.cart) {
      throw new Error('Failed to create line item')
    }

    return mapGQLCartToCart(response.data.cartLinesAdd.cart)
  }
