import { CustomerUserError } from 'shopify-types'

import { storefrontClient } from '..'

type RecoverCustomerResponse = Readonly<{
  customerRecover: {
    customerUserErrors: CustomerUserError[]
  }
}>

export const recoverCustomer =
  () =>
  async (email: string): Promise<void> => {
    const response = await storefrontClient.request<RecoverCustomerResponse>(
      `#graphql
        mutation customerRecover($email: String!) {
          customerRecover(email: $email) {
            customerUserErrors {
              field
              message
              code
            }
          }
        }
      `,
      {
        variables: {
          email,
        },
      },
    )

    if (!response.data || response.data.customerRecover.customerUserErrors.length > 0) {
      throw new Error('Failed to recover customer')
    }
  }
