import { Order, OrderFinancialStatus, OrderFulfillmentStatus } from 'shopify-types'

import { GQLMoney, mapGQLMoneyToMoney } from '../../graphql'
import {
  GQL_VARIANT_FRAGMENT,
  GQLProductVariant,
  mapGQLProductVariantToProductVariant,
} from '../../product/graphql'

export type GQLOrder = Readonly<{
  id: string
  name: string
  orderNumber: number
  totalPrice: GQLMoney
  statusUrl: string
  financialStatus: OrderFinancialStatus
  fulfillmentStatus: OrderFulfillmentStatus
  processedAt: string
  lineItems: {
    edges: {
      node: {
        title: string
        quantity: number
        originalTotalPrice: GQLMoney
        discountedTotalPrice: GQLMoney
        variant: GQLProductVariant
      }
    }[]
  }
}>

export const GQL_ORDER_FRAGMENT = `#graphql
  id
  name
  orderNumber
  totalPrice {
    amount
    currencyCode
  }
  statusUrl
  financialStatus
  fulfillmentStatus
  processedAt
  lineItems(first: 250) {
    edges {
      node {
        title
        quantity
        originalTotalPrice {
          amount
          currencyCode
        }
        discountedTotalPrice {
          amount
          currencyCode
        }
        variant {
          ${GQL_VARIANT_FRAGMENT}
        }
      }
    }
  }
` as const

export const mapGQLOrderToOrder = (order: GQLOrder): Order => ({
  ...order,
  totalPrice: mapGQLMoneyToMoney(order.totalPrice),
  lineItems: order.lineItems.edges.map(lineItem => ({
    ...lineItem.node,
    variant: mapGQLProductVariantToProductVariant(lineItem.node.variant),
    originalTotalPrice: mapGQLMoneyToMoney(lineItem.node.originalTotalPrice),
    discountedTotalPrice: mapGQLMoneyToMoney(lineItem.node.discountedTotalPrice),
  })),
})
