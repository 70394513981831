import { CustomerAccessToken, CustomerUserError } from 'shopify-types'

import { storefrontClient } from '..'

type LoginCustomerInput = Readonly<{
  email: string
  password: string
}>

type LoginCustomerResponse = Readonly<{
  customerAccessTokenCreate: {
    customerAccessToken: CustomerAccessToken
    customerUserErrors: CustomerUserError[]
  }
}>

export const loginCustomer =
  () =>
  async (input: LoginCustomerInput): Promise<CustomerAccessToken> => {
    const response = await storefrontClient.request<LoginCustomerResponse>(
      `#graphql
        mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
          customerAccessTokenCreate(input: $input) {
            customerAccessToken {
              accessToken
              expiresAt
            }
            customerUserErrors {
              field
              message
              code
            }
          }
        }
      `,
      {
        variables: {
          input,
        },
      },
    )

    if (!response.data?.customerAccessTokenCreate.customerAccessToken.accessToken) {
      throw new Error('Failed to login customer')
    }

    return response.data.customerAccessTokenCreate.customerAccessToken
  }
