import clsx from 'clsx'
import { useMemo } from 'react'

type LoaderProps = {
  color: 'black' | 'white'
}

export default function Loader({ color }: LoaderProps) {
  const className = useMemo(
    () => clsx('animate-pulse', { black: 'fill-black', white: 'fill-white' }[color]),
    [color],
  )

  return (
    <svg width={40} height={40} fill='none'>
      <path
        className={className}
        style={{ animationDelay: '-1750ms' }}
        d='M18.75 10.003a1.25 1.25 0 0 0 2.5 0V5a1.25 1.25 0 1 0-2.5 0v5.003z'
      />
      <path
        className={className}
        style={{ animationDelay: '-1500ms' }}
        d='M27.067 14.18c-.319 0-.64-.122-.884-.367h-.003c-.285-.286-.411-.546-.411-.807 0-.295.164-.593.447-.939l-.027-.026c.053-.052.111-.1.169-.14.219-.248.49-.52.805-.834l1.953-1.951a1.25 1.25 0 1 1 1.768 1.765l-2.918 2.92-.016.013a1.243 1.243 0 0 1-.883.366z'
      />
      <path
        className={className}
        style={{ animationDelay: '-1250ms' }}
        d='M35 18.75a1.25 1.25 0 1 1 0 2.5h-5.005A1.249 1.249 0 0 1 28.748 20c0-.69.557-1.25 1.247-1.25H35z'
      />
      <path
        className={className}
        style={{ animationDelay: '-1000ms' }}
        d='m27.95 26.181.016.014-.005-.001 2.919 2.919c.245.242.367.562.367.882a1.25 1.25 0 0 1-2.133.883l-1.953-1.951-.072-.072-.01-.01a18.626 18.626 0 0 1-.723-.75 1.12 1.12 0 0 1-.168-.14l.026-.025c-.534-.65-.64-1.136-.034-1.747a1.257 1.257 0 0 1 1.77-.002z'
      />
      <path
        className={className}
        style={{ animationDelay: '-750ms' }}
        d='M21.25 29.997V35a1.25 1.25 0 1 1-2.5 0v-5.003a1.25 1.25 0 0 1 2.5 0z'
      />
      <path
        className={className}
        style={{ animationDelay: '-500ms' }}
        d='M12.049 26.183a1.253 1.253 0 0 1 1.77.001h-.003c.284.286.41.546.41.807 0 .295-.163.593-.446.939l.026.026c-.053.052-.11.1-.168.14-.22.248-.491.52-.805.834L10.88 30.88a1.236 1.236 0 0 1-.883.367 1.25 1.25 0 0 1-.883-2.132l2.919-2.92a.111.111 0 0 1 .016-.013z'
      />
      <path
        className={className}
        style={{ animationDelay: '-250ms' }}
        d='M10.048 18.75a1.25 1.25 0 1 1 0 2.5H5.043A1.249 1.249 0 0 1 3.796 20c0-.69.556-1.25 1.247-1.25h5.005z'
      />
      <path
        className={className}
        d='m10.95 9.126.015.014-.005-.001 2.919 2.919c.245.242.367.562.367.882a1.25 1.25 0 0 1-2.133.883l-1.953-1.951-.072-.072-.009-.01a18.626 18.626 0 0 1-.723-.75 1.12 1.12 0 0 1-.17-.14l.027-.025c-.534-.65-.64-1.136-.034-1.747a1.257 1.257 0 0 1 1.77-.002z'
      />
    </svg>
  )
}
