import { Cart } from 'shopify-types'

import { storefrontClient } from '..'
import { GlobalRequestOptions } from '../types'
import { GQL_CART_MUTATION_QUERY, GQLCartMutation, mapGQLCartToCart } from './graphql'

type RemoveCartLineResponse = Readonly<{
  cartLinesRemove: GQLCartMutation
}>

export const removeCartLine =
  (options: GlobalRequestOptions) =>
  async (cartId: string, lineIds: string[]): Promise<Cart> => {
    const response = await storefrontClient.request<RemoveCartLineResponse>(
      `#graphql
        mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!, $language: LanguageCode) @inContext(language: $language) {
          cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
            ${GQL_CART_MUTATION_QUERY}
          }
        }
      `,
      {
        variables: {
          cartId,
          lineIds,
          language: options.language,
        },
      },
    )

    if (!response.data?.cartLinesRemove.cart) {
      throw new Error('Failed to remove line item')
    }

    return mapGQLCartToCart(response.data.cartLinesRemove.cart)
  }
