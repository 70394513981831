import { Cart } from 'shopify-types'

import { storefrontClient } from '..'
import { GlobalRequestOptions } from '../types'
import { GQL_CART_FRAGMENT, GQLCart, mapGQLCartToCart } from './graphql'

type FetchCartResponse = Readonly<{
  cart: GQLCart
}>

export const fetchCart =
  (options: GlobalRequestOptions) =>
  async (id: string): Promise<Cart> => {
    const response = await storefrontClient.request<FetchCartResponse>(
      `#graphql
        query ($id: ID!, $language: LanguageCode) @inContext(language: $language) {
          cart(id: $id) {
            ${GQL_CART_FRAGMENT}
          }
        }
      `,
      {
        variables: {
          id,
          language: options.language,
        },
      },
    )

    if (!response.data) {
      throw new Error('Failed to fetch cart')
    }

    return mapGQLCartToCart(response.data.cart)
  }
