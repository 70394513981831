import { withGlobalOptions } from '../types'
import { addressHandles } from './address'
import { createCustomer } from './create'
import { fetchCustomer } from './fetch'
import { loginCustomer } from './login'
import { logoutCustomer } from './logout'
import { orderHandles } from './order'
import { recoverCustomer } from './recover'
import { refreshCustomer } from './refresh'

export const customerHandles = withGlobalOptions({
  fetch: fetchCustomer,
  create: createCustomer,
  login: loginCustomer,
  refresh: refreshCustomer,
  logout: logoutCustomer,
  recover: recoverCustomer,
  address: addressHandles,
  order: orderHandles,
})
